import mixpanel from 'mixpanel-browser';

const env_check = process.env.NODE_ENV === 'production';

mixpanel.init('5f39e36bbbee6a39767fc439f5caffcf', {
  debug: false,
  batch_requests: true
});

let actions = {
  identify: (email) => {
    if (env_check) mixpanel.identify(email);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  track_links: (cssSelector, name, props) => {
    if (env_check) mixpanel.track_links(cssSelector, name, props);
  },
  loaded: () => {
    return;
  },

  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
