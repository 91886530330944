import axios from 'axios';
import { API_URL } from './constants';
import { getAccessToken } from '../utils/information';

let token = getAccessToken();

axios.defaults.baseURL = API_URL;
if (token !== null) {
  axios.defaults.headers.common['Authorization'] = token;
}
axios.defaults.headers.post['Content-Type'] = 'application/json';
