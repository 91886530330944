import React, { useEffect, useState, lazy } from 'react';
import { ChatStart, GetExampleTerms } from '../../../api/services/applications';
import { Mixpanel } from '../../../utils/mixPanel';
import $ from 'jquery';
import { footerMenu } from '../../../utils/footer-menu';
import BasicModal from '../../../components/Modals/BasicModal';

//Icons
import { BsArrowRightShort, BsRocketTakeoff } from 'react-icons/bs';
import { AiOutlineArrowRight } from 'react-icons/ai';

const ChatItem = lazy(() =>
  import('../../../components/Platform/Task/ChatItem'),
);
const SwalAlert = lazy(() => import('../../../utils/swal-alert'));
const ChatLoading = lazy(() =>
  import('../../../components/Platform/Task/ChatLoading'),
);

const Task = ({ isPublic = false }) => {
  const [term, setTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const subscriptionSuccess = urlParams.get('subscription');
  const defaultMessage = urlParams.get('message');
  const [chat, setChat] = useState([]);
  const [chatId, setChatId] = useState('');
  const [exampleText, setExampleText] = useState();
  const [defaultText, setDefaultText] = useState();
  const [exampleTerms, setExampleTerms] = useState([]);

  useEffect(() => {
    GetExampleTerms({}).then((result) => {
      if (result.status === 200) {
        setExampleTerms(result.data);
      } else {
        SwalAlert(result);
      }
    });
  }, []);

  useEffect(() => {
    if (subscriptionSuccess) {
      $('#successSubscriptionModal').modal('show');
    }
  }, [subscriptionSuccess]);

  useEffect(() => {
    if (isPublic) {
      setChatId();
    } else {
      ChatStart({}).then((result) => {
        if (result.status) {
          setChatId(result.data.chat_id);
        } else {
          SwalAlert(result);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (defaultMessage) {
      setTerm(defaultMessage);
      urlParams.delete('message');
      const newUrl = `${window.location.pathname}${urlParams.toString()}`;
      window.history.replaceState({}, '', newUrl);
      setTimeout(() => {
        setDefaultText(Math.random());
      }, 400);
    }
  }, [defaultMessage]);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 200);
  }, [chat]);

  const loadMoreClick = (index) => {
    let resultTerm = chat[index - 1].message;
    Mixpanel.track('Chat Show More Button', {
      Term: resultTerm,
    });
    setTimeout(() => {
      scrollToBottom();
    }, 200);
  };

  useEffect(() => {
    if (term.length !== 0) {
      handleSubmit();
    }
  }, [exampleText, defaultText]);

  const handleExampleText = (value) => {
    Mixpanel.track('Chat Click', {
      Type: 'Example Text',
      Term: value,
    });
    setTerm(value);
    setExampleText(Math.random());
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (term?.length !== 0) {
      setChat((chat) => [
        ...chat,
        { user: 'user', type: 'message', message: term, data: '' },
      ]);
      handleSearch();
      setError('');
    } else {
      setError(
        "You haven't entered any sentence, as if. Please enter a sentence.",
      );
    }
  };

  const handleSearch = () => {
    setLoading(true);
    Mixpanel.loaded();
    Mixpanel.track('Term', {
      Term: term,
    });
    let body = {
      term,
    };
    if (chatId) {
      body.chatId = chatId;
    }
    setTerm('');
    fetch(
      'https://bmj25dy2g5ghdveqlx2fsv2ada0pepax.lambda-url.eu-west-1.on.aws',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...body }),
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error('Something wrong!');
        }
      })
      .then((result) => {
        setChat((chat) => [
          ...chat,
          {
            user: 'assistant',
            type: result.type,
            message: result.message,
            data: result.data,
          },
        ]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleChange = (value) => {
    setTerm(value);
  };

  const isCheckSubmit = () => {
    if (term.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <BasicModal modalSize="modal-lg" id="successSubscriptionModal">
        <div className="d-flex flex-column align-items-center justify-content-center p-6">
          <h1 className="font-size-5xl text-primary font-weight-700 ">
            Loading Credit!
          </h1>
          <span className="d-block text-center font-size-lg text-dark ">
            Your payment request has been received and is being processed. Once
            the payment is successfully processed, it will be credited to your
            account.
          </span>
          <span className="d-block text-center mt-2 font-size-lg text-dark font-weight-bold">
            This process typically takes 1 to 3 minutesto complete.
          </span>
          <button
            onClick={() => (window.location.href = '/task')}
            className="btn btn-primary mt-4">
            Okay
          </button>
        </div>
      </BasicModal>

      <div className="w-100 pb-80px d-flex flex-column align-items-center overflow-auto ">
        {chat?.length === 0 && (
          <div className="w-100 py-4 px-2 px-md-0 d-flex justify-content-center  ">
            <div className="w-100 w-md-90 w-lg-80 w-xl-60 d-flex jusstify-content-start align-items-start p-3 p-md-0">
              <div className=" w-100 pt-1 pt-md-3 position-relative">
                <h1 className="font-weight-700 font-size-4xl text-dark-primary w-100 ">
                  Hundreds of Generative AI Solutions
                  <BsRocketTakeoff className="ml-2" />
                </h1>
                <span className="text-dark">
                  Plugger.ai has been designed to provide you with audio, image,
                  and video solutions. All you need to type your need and we
                  will handle the rest of it.
                </span>
                <div className="divider-line w-100 my-4" />
                <h5 className="m-0 p-0 mt-4 text-underline">
                  Don't know where to start? You can try saying:
                </h5>
                <div className="py-2 mt-2 row text-dark d-flex  flex-column flex-md-row align-items-stretch ">
                  {exampleTerms.map((i, index) => (
                    <div className="col-12 col-md-4 mb-3 mb-md-0 d-flex">
                      <div
                        key={index}
                        onClick={() => handleExampleText(i.value)}
                        className="card rounded-0 border pt-3 pt-md-4 px-3 px-md-4 pb-3 pb-md-4 cursor-pointer text-dark w-100 flex-row align-items-center">
                        <span className="w-md-90 w-80 ">{i.value}</span>
                        <div className="position-absolute bottom-0 right-0  bg-e3e3e3  p-1 zindex-50">
                          <BsArrowRightShort className="display-5" />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
        {chat?.map((i, index) => (
          <div key={index} className="w-100">
            <ChatItem
              user={i.user}
              message={i.message}
              data={i.data}
              type={i.type}
              onClickLoadMore={() => loadMoreClick(index)}
            />
          </div>
        ))}
        {loading && (
          <div className="w-100">
            <ChatLoading />
          </div>
        )}
      </div>

      <div className="position-fixed bottom-0 h-80px px-2 px-md-0 w-100  d-flex flex-column align-items-center justify-content-center justify-content-md-start bg-white">
        <div
          onKeyDown={handleKeyDown}
          className="usecase-input-group w-100 w-md-90 w-lg-80 w-xl-60  shadow-xl">
          <input
            type="text"
            value={term}
            className={error ? 'bg-soft-warning' : 'bg-white'}
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Send messages "
          />

          <button
            disabled={isCheckSubmit()}
            onClick={() => handleSubmit()}
            className="btn btn-sm btn-primary">
            <AiOutlineArrowRight />
          </button>
        </div>
        <div className="d-none d-md-flex mt-1 justify-content-center">
          {footerMenu.map((i, index) => (
            <a
              key={index}
              href={i.url}
              target="blank"
              className="mx-2 text-dark-primary">
              {i.name}
            </a>
          ))}
        </div>
      </div>
    </>
  );
};

export default Task;
