import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import Core from './layouts/Core';
import initGA from './utils/ga';
import { getLanguage } from './utils/i18n';

import './utils/i18n';
import './assets/js/theme';
import './assets/vendor/icon-set/style.css'; //tio icon set
import './assets/css/theme.css'; // general CSS file
import './assets/css/datatables.responsive.css';

// HMTL lang set browser language
document.documentElement.lang = getLanguage();

// Google Analytics
initGA();

const Loading = () => {
  return (
    <div className="d-flex justify-content-center align-items-center position-absolute top-0 right-0 left-0 bottom-0">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

Sentry.init({
  dsn:
    process.env.NODE_ENV === 'production'
      ? 'https://c5c4fc2bac5b4a31bc6158bff2c131fc@o1056985.ingest.sentry.io/6043493'
      : '',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const rootElement = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <HttpsRedirect>
        <Core />
      </HttpsRedirect>
    </Suspense>
  </React.StrictMode>,
  rootElement,
);
