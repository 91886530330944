export const footerMenu = [
  { name: 'Docs', url: 'https://documentation.cameralyze.co/' },
  {
    name: 'Partnership',
    url: 'https://www.plugger.ai/cameralyze-for-partnership',
  },
  {
    name: 'Status',
    url: 'https://cameralyze.statuspage.io/',
  },
  {
    name: 'Terms',
    url: 'https://www.plugger.ai/terms-and-condition',
  },
  {
    name: 'Discord',
    url: 'https://discord.com/invite/7hSFd5Xtx7',
  },
  {
    name: 'Linkedin',
    url: 'https://www.linkedin.com/company/pluggerai/',
  },
];

