import React from 'react';

const BasicModal = ({
  title,
  children,
  id,
  modalSize,
  className,
  onClose,
  dismiss = 'modal',
}) => {
  return (
    <div
      className={'modal ' + className}
      id={id}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="basicModal"
      aria-hidden="true">
      <div className={'modal-dialog  ' + modalSize} role="document">
        <div className="modal-content">
          <div className="modal-header p-2">
            <h5 className="modal-title" id="exampleModalLabel">
              {title}
            </h5>
            <button
              onClick={onClose}
              type="button"
              className="btn btn-xs btn-icon btn-ghost-secondary"
              data-dismiss={dismiss}
              aria-label="Close">
              <i className="tio-clear tio-lg" />
            </button>
          </div>
          <div className="model-body  ">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default BasicModal;
