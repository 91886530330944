import React, { useEffect, useState, lazy } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';

import { getAccessToken, getRefreshToken } from '../utils/information';
import { Mixpanel } from '../utils/mixPanel';
import Chat from '../views/Platform/Task/Chat';

const Assistant = lazy(() => import('../views/Platform/Assistant/Assistant'));
const Platform = lazy(() => import('./Platform'));
const _404 = lazy(() => import('../views/Pages/404'));
const Auth = lazy(() => import('./Auth'));
const Welcome = lazy(() => import('../views/User/Welcome'));
const ModelFrame = lazy(() => import('../views/Platform/Models/ModelFrame'));
const UpdateSubscription = lazy(() =>
  import('../views/Account/UpdateSubscription'),
);
const PriceCalculator = lazy(() =>
  import('../views/Platform/Tool/PriceCalculator'),
);
const Logout = lazy(() => import('../views/Auth/Logout'));

export const GlobalContext = React.createContext([]);
export const NavigationContext = React.createContext([]);
const redirectUrl = window.location.href;

const auth = () => (getAccessToken() && getRefreshToken() ? true : false);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth() === true ? (
        <Component {...props} />
      ) : (
        <Redirect to={'/auth/sign-up?redirect=' + redirectUrl} />
      )
    }
  />
);

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth() === false ? <Component {...props} /> : <Redirect to="/" />
    }
  />
);

const Core = () => {
  const [globalState, setGlobalState] = useState([]);
  const globalValue = { globalState, setGlobalState };
  const [navigationState, setNavigationState] = useState([]);
  const navigationValue = { navigationState, setNavigationState };
  const location = window.location.href;
  const pathName = window.location.pathname;

  useEffect(() => {
    Mixpanel.loaded();
    Mixpanel.track('Pageview', {
      'Page Path': location,
    });
    if (pathName === '/task') {
      window.location.href = '/assistant';
    }
    if (pathName.includes('/tool')) {
      window.location.href = '/assistant';
    }
  }, [location]);

  return (
    <GlobalContext.Provider value={globalValue}>
      <NavigationContext.Provider value={navigationValue}>
        <Router>
          <Switch>
            <Route exact path="/404" component={_404} />
            <PublicRoute path="/auth" component={Auth} />
            <Route path="/logout" component={Logout} />
            <PrivateRoute exact path="/account" component={Platform} />
            <PrivateRoute exact path="/user/welcome" component={Welcome} />
            <Route path="/model-frame/:itemUuid" component={ModelFrame} />

            <Route path="/chat" component={Chat} />
            <Route path="/pricing-calculator" component={PriceCalculator} />
            <PrivateRoute
              exact
              path="/settings/subscription/update"
              component={UpdateSubscription}
            />
            <PrivateRoute path="/platform" component={Platform} />
            <PrivateRoute path="/" component={Assistant} />
          </Switch>
        </Router>
      </NavigationContext.Provider>
    </GlobalContext.Provider>
  );
};

export default Core;
