import ReactGA from "react-ga";

export const initGA = () => {
    ReactGA.initialize("G-0N6LG1953Y", { debug: false });
};

export const GAPageView = path => {
    let root = window.location.pathname + window.location.search;
    ReactGA.pageview(path || root);
};

export const GAModalView = name => {
    ReactGA.modalview(name);
};

export const GAEvent = args => {
    ReactGA.event({ ...args });
};

export default initGA;
