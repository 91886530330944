/*
 * Plugger.ai — PluggerCore
 * @version: 1.0.0 (03 Nov 2020)
 * @requires: jQuery v3.0 or later
 * @author: yavuz&ufuk
 * @event-namespace: .PluggerCore
 * Copyright 2020 Plugger.ai
 */
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../vendor/navbar-vertical-aside/navbar-vertical-aside';
import '../vendor/navbar-vertical-aside/navbar-mini-cache';
import '../vendor/unfold/unfold';

$.extend({
  CMZCore: {
    init: function () {
      $(document).ready(function () {
        // Botostrap Tootltips
        $('[data-toggle="tooltip"]').tooltip();

        // Bootstrap Popovers
        $('[data-toggle="popover"]').popover();
      });
    },

    components: {},
  },
});

$.CMZCore.init();

export const reactSelectStyle = {
  control: (base, state) => ({
    ...base,
    border: '1px solid #ccc',
    borderRadius: '10px',
    boxShadow: 'inset 1px 2px 4px 1px rgba(0, 0, 0, 0.15)',
    padding: '2px',
    '&:hover': {
      border: '1px solid #999',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? ' #ebf3e8' : 'white',
    color: '#000',
    '&:hover': {
      backgroundColor: ' #ebf3e8',
      color: '#000',
      cursor: 'pointer',
    },
  }),
  multiValue: (provided, state) => ({
    ...provided,
    border: '1px solid #598B40',
    color: 'red',
  }),
};
