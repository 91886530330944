export const capacityExecitionList = [
  { id: 2, value: '10000' },
  { id: 3, value: '50000' },
  { id: 4, value: '100000' },
  { id: 5, value: '250000' },
  { id: 6, value: '500000' },
  { id: 7, value: '1000000' },
  { id: 8, value: '5000000' },
  { id: 9, value: '50000000' },
  { id: 10, value: '100000000' },
];

export const monthlySubscriptions = [
  {
    id: 6,
    uuid: 'c0925f40-07a5-4641-8883-fed06c8039d3',
    name: 'Starter',
    descripton: 'For Small Teams',
    price: '0',
    options: ['Public Model Access', 'Public Model Upload'],
    type: 'Monthly',
    link: '',
  },
  {
    id: 7,
    uuid: '7d8af824-d180-4630-9f90-163bcf6d3c10',
    name: 'Lite',
    descripton: 'For Small Teams',
    price: '29',
    options: [
      'Public Model Access',
      'Public Model Upload',
      '1 Private Model Upload',
      '1 Flow Generator',
      '50 GB Storage',
    ],
    type: 'Monthly',
    link: 'https://buy.stripe.com/dR69Cr0VPcP7amQ6oq',
  },
  {
    id: 8,
    uuid: '1793c9c7-28e5-4aec-ad94-527f6f200856',
    name: 'Professional',
    descripton: 'For Companies',
    price: '99',
    options: [
      'Public Model Access',
      'Public Model Upload',
      'Private Model Upload',
      'Flow Generator',
      'Team Management',
      '250 GB Storage',
      'Managed Databases',
    ],
    type: 'Monthly',
    link: 'https://buy.stripe.com/aEUg0P5c53examQ7st',
  },
];

export const annuallySubscriptions = [
  {
    id: 5,
    name: 'Community',
    descripton: 'For Community',
    price: 'Free',
    options: [
      '1 User',
      '100 Frames / per month',
      '1 Application',
      'Preview and Share Feature',
      'Image File Support',
    ],
    type: 'Monthly',
  },
  {
    id: 6,
    name: 'Starter',
    descripton: 'For Small Teams',
    price: '$228',
    options: [
      '2 Users',
      '500 Frames / per month',
      '3 Applications',
      '3 Premium Integrations',
      'Preview and Share Feature',
      'Additional Frame Capacity: 1.000 extra frames $5/per month',
      'Image File Support',
      'Video File Support',
    ],
    type: 'Annually',
  },
  {
    id: 7,
    name: 'Growth',
    descripton: 'For Startups & SMB',
    price: '$948',
    options: [
      'Up to 5 Users',
      '2.500 Frames / per month',
      '10 Applications',
      '10 Premium Integrations',
      'Access to Cameralyze Live',
      'Preview and Share Feature',
      'Custom Model Training',
      'Additional Frame Capacity: 1.000 extra frames $3.5/per month',
      'CCTV Live Stream Support',
      'Image File Support',
      'Video File Support',
    ],
    type: 'Annually',
  },
  {
    id: 8,
    name: 'Professional',
    descripton: 'For Companies',
    price: '$5998',
    options: [
      'Unlimited Users',
      '20.000 Frames / per month',
      'Unlimited Applications',
      'Unlimited Integrations',
      'Custom Model Training',
      'Access to Cameralyze Edge',
      'Access to Cameralyze Live',
      'Preview and Share Feature',
      'Custom Model Platform',
      'AI Model Upload',
      'Additional Frame Capacity: 1.000 extra frames $2/per month',
      'CCTV Live Stream Support',
      'Image File Support',
      'Video File Support',
    ],
    type: 'Annually',
  },
];
